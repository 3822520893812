import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phonefilter'
})
export class PhoneMaskPipe implements PipeTransform {

  transform(number: string): string {
    if (number && number.length < 10) {
      return number;
    }

    const visibleDigits = 4;
    let maskedSection = number.slice(0, -visibleDigits);
    let visibleSection = number.slice(-visibleDigits);
    let masked = maskedSection.replace(/./g, '*');
    return ' (' + masked.substring(0, 3) + ') ' + masked.substring(3, 7) + '-' + visibleSection;

  }
}
