import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { NgModule, ErrorHandler } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { APP_BASE_HREF } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

// Forms
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Main view components
import { LoginComponent } from './login/login.component';
import { AggregatorComponent } from './aggregator/aggregator.component';

// Error Components
import { AccessDeniedComponent } from './error/access-denied/access-denied.component';
import { PageNotFoundComponent } from './error/page-not-found/page-not-found.component';

// Shared Services
import { PreviousUrlService } from './shared/PreviousUrl.Service';
import { GlobalHeaderComponent } from './global-header/global-header.component';
import { GlobalFooterComponent } from './global-footer/global-footer.component';
import { LoginService } from './shared/Login.Service';
import { TrusteerService } from './shared/Trusteer.Service';
import { AppInsightsService } from './shared/AppInsights.Service';
import { ErrorHandlerService } from './shared/ErrorHandler.Service';

// pipes
import { EmailMaskPipe } from './pipe/email-mask.pipe';
import { PhoneMaskPipe } from './pipe/phone-mask.pipe';
import { SSNMaskPipe } from './pipe/ssn-mask.pipe';
import { AuthInterceptor } from './shared/auth.interceptor';
import { LockedComponent } from './error/locked/locked.component';
import { UnauthorizedComponent } from './error/Unauthorized/Unauthorized.component';
import { ExpiredPwComponent } from './expired-pw/expired-pw.component';
import { BrokerLockedComponent } from './error/broker-locked/broker-locked.component';
import { HostRedirectPipe } from './pipe/host-redirect.pipe';
import { ValidationPassLen } from './pipe/validation-pass-len.pipe';
import { ValidationPassLow } from './pipe/validation-pass-low.pipe';
import { ValidationPassNum } from './pipe/validation-pass-num.pipe';
import { ValidationUidLen } from './pipe/validation-uid-len.pipe';
import { ValidationPassUp } from './pipe/validation-pass-up.pipe';
import { ValidationUidNum } from './pipe/validation-uid-num.pipe';
import { ValidationPass } from './pipe/validation-uid-specialChars.pipe';
import { CesLoginComponent } from './ces-login/ces-login.component';
const routes: Routes = [
  // { path: '', component: LoginComponent},
  { path: '', component: LoginComponent },
  { path: 'ces', component: CesLoginComponent },
  { path: 'update', component: ExpiredPwComponent },
  { path: 'ces/update', component: ExpiredPwComponent },
  { path: 'brokerLock', component: BrokerLockedComponent },
  { path: 'ces/brokerLock', component: BrokerLockedComponent },
  { path: 'locked', component: LockedComponent },
  { path: 'ces/locked', component: LockedComponent },
  { path: 'aggregator', component: AggregatorComponent },
  { path: '404', component: PageNotFoundComponent},
  { path: '401', component: AccessDeniedComponent},
  { path: 'Unauthorized', component: UnauthorizedComponent },
  { path: '**', redirectTo: '/404'}
];

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    AggregatorComponent,
    AccessDeniedComponent,
    PageNotFoundComponent,
    GlobalHeaderComponent,
    GlobalFooterComponent,
    EmailMaskPipe,
    PhoneMaskPipe,
    SSNMaskPipe,
    LockedComponent,
    ExpiredPwComponent,
    HostRedirectPipe,
    ValidationPassLen,
    ValidationPassLow,
    ValidationPassNum,
    ValidationUidLen,
    ValidationUidNum,
    ValidationPassUp,
    ValidationPass,
    BrokerLockedComponent,
    UnauthorizedComponent,
    CesLoginComponent
  ],
  imports: [
    BrowserModule,
    CommonModule,
    RouterModule.forRoot(routes,{ scrollPositionRestoration: 'enabled' }),
    NgbModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule
  ],
  exports:[HostRedirectPipe],
  providers: [LoginService, TrusteerService, PreviousUrlService, {provide: APP_BASE_HREF, useValue : '/login/' },
              {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
              {provide: ErrorHandler, useClass: ErrorHandlerService},
               AppInsightsService],
  bootstrap: [AppComponent]
})
export class AppModule { }
