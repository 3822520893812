import { Component, OnInit } from '@angular/core';
import { LoginService } from 'src/app/shared/Login.Service';
import { ActivatedRoute } from '@angular/router'; 
@Component({
  selector: 'app-unauthorized',
  templateUrl: './Unauthorized.component.html',
  styleUrls: ['./Unauthorized.component.scss']
})
export class UnauthorizedComponent implements OnInit {
  constructor(private route:ActivatedRoute) { }
  public resetUrl = '';
  public errorCode:any=''; 
  ngOnInit() {
    this.route.params.subscribe(params => {
            this.errorCode= !!params.errorCode ? params.errorCode : '113';
    });
  }

}
