import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ssnfilter'
})
export class SSNMaskPipe implements PipeTransform {

  transform(value: string): string {
    if (value && value.length < 9) {
      return value;
    }
    return '***-**-' + value.substr(0, value.length - 6);
  }
}
