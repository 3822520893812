import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
  name: 'passLen'
})
export class ValidationPassLen implements PipeTransform {

  constructor() { }

  transform(value: string): string {
    // 8-24 char
    if (value && value.length >= 8 && value.length <= 24) {
      return 'valid';
    }
   return 'invalid';
  }
}
