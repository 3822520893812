import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
  name: 'vuidnum'
})
export class ValidationUidLen implements PipeTransform {

  constructor() { }

  transform(value: string): string {
    // 1 number
    if (value && value.match('.*\\d.*')) {
      return 'valid';
    }
    // Min 7 characters
   return 'invalid';
  }
}
