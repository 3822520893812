import { Component, OnInit } from '@angular/core';
import { LoginService } from 'src/app/shared/Login.Service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-locked',
  templateUrl: './locked.component.html',
  styleUrls: ['./locked.component.scss']
})
export class LockedComponent implements OnInit {
  constructor(private route: ActivatedRoute,
    private router: Router) { }
  public resetUrl = '';
  public errorCode: any = '';
  public isCes: boolean = false;
  public isEqh: boolean = false;
  ngOnInit() {
    if (this.router.url.indexOf('ces') !== -1) {
      this.isCes = true;
    } else {
      this.isEqh = true;
    }
    this.route.params.subscribe(params => {
      this.errorCode = !!params.errorCode ? params.errorCode : '113';
    });

    if (this.isCes)
      this.resetUrl = window.location.origin + '/forgot/ces';
    else {
      if (window.location.origin.includes('/login')) {
        this.resetUrl = window.location.origin.replace('/login', '/forgot');
      } else {
        this.resetUrl = window.location.origin + '/forgot';
      }
    }

  }

}
