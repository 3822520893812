import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-global-header',
  templateUrl: './global-header.component.html',
  styleUrls: ['./global-header.component.scss']
})
export class GlobalHeaderComponent implements OnInit {

  public isCes: boolean = false;
  public isEqh: boolean = false;

  constructor(private router: Router) { }

  ngOnInit() {
  	console.log(this.router.url);
  	if(this.router.url.indexOf('/ces') !== -1){
      this.isCes = true;
    }else{
      this.isEqh = true;
    }
  }

}
