import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
  name: 'passNum'
})
export class ValidationPassNum implements PipeTransform {

  constructor() { }

  transform(value: string): string {
    // 1 number or special char
    //const regex = /^[A-Za-z]+$/;
    const regex = /(?=.*\d)/;
    if (value && regex.test(value)) {
      return 'valid';
    }
    // Min 7 characters
   return 'invalid';
  }
}
