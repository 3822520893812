import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
  name: 'passLow'
})
export class ValidationPassLow implements PipeTransform {

  constructor() { }

  transform(value: string): string {
    // Lower Case
    if (/[a-z]+/.test(value)) {
      return 'valid';
    }
   return 'invalid';
  }
}
