import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
  name: 'vuspecialchar'
})
export class ValidationPass implements PipeTransform {

  constructor() { }

  transform(value: string): string {
    // 1 special char
    const regex = /([~`!@#$%^&*+()=_{}\\|\"\':;<>,.?])|(\[|\]|\/|\-)/;
    if (value && regex.test(value)) {
      return 'valid';
    }
   return 'invalid';
  }
}
