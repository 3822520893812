import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { User } from '../shared/user.model';
import { LoginService } from '../shared/Login.Service';
import { TrusteerService } from '../shared/Trusteer.Service';
import { errorList } from '../shared/error'; // global error list
import { Login } from '../shared/login.model';
import { AppInsightsService } from '../shared/AppInsights.Service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  @Input() tbox: string;
  public uuid: string;
  toggle = false;                
  user: User;
  login: Login;
  public message = '';
  public error = false;
  public remember: boolean;
  public reset: boolean;
  
  constructor(
    private router: Router,
    private loginService: LoginService,
    private trusteerService: TrusteerService,
    private appInsightsService: AppInsightsService
  ) { }

  ngOnInit() {
    window.scroll(0, 0);
    this.user = new User();
    this.user.userID = '';
    this.login = new Login('','', '');
    this.user.password = '';
    this.user.dfaToken = '';
    this.user.phonenumber = '';
    this.toggle = false;
    this.appInsightsService.logPageView("Login UI Page View");
    // This is for invalid userId for update password.
    this.loginService.getError().subscribe(res => {
      this.error = res;
      if (this.error) {
        this.loginService.getMsg().subscribe(msg => {
          this.message = msg;
          window.scrollTo(0, 0);
        });
      }
    });
    this.loginService.getUser().subscribe(res => {
      this.user = res; // retrieve user id
      if (this.user) {
        window.scrollTo(0, 0);
        console.log('remember: ' + this.user.remember);
        if (this.user.alert && this.user.alert === 'reset') {
          this.reset = true;
          this.message = 'Your password has been updated successfully.';
        }
        if (this.user.alert && this.user.alert === 'resetId') {
          this.reset = true;
          this.message = 'Your User Id has been updated successfully.';
        }
        if (this.user.alert && this.user.alert === 'forgotId') {
          this.reset = true;
          this.message = 'Your User Id is: ' + this.user.userID;
        }
        if (this.user.alert && this.user.alert === 'complete') {
          this.error = true;
          this.message = 'Session has expired please close browser and try again';
        }
        if (this.user.alert && this.user.alert === 'generalError') {
          this.error = true;
          this.message = 'We were unable to process your request at this time, please try again later.';
        }
        if (this.user.alert && this.user.alert === 'selfPwd') {
          this.reset = true;
          this.message = 'Password was sent to email on record.';
        }
      }
    });
    this.generateSession();
    this.trusteerService.getImageTrusteer(this.uuid);
  }

  passwordKeyup(user) {
    if (!user.password) {
      this.error = false;
      this.reset = false;
      this.message = '';
    }
  }

  onSubmit(user: User) {
    if (user && user.userID && user.password) {
      user.userID = user.userID.trim();
      user.remember = false;
      this.user.session = this.uuid;
      console.log(user.userID);
      this.loginService.setUid(user.userID);
      this.loginService.getLogin(user).subscribe(res => {
        this.login = res;
        if (this.login) {
          if (this.login.url.length === 0) {
            this.error = true;
            this.reset = false;
            
            if(this.getCookie('queryString') && this.getCookie('queryString').indexOf('eb-claims') > -1)
            	this.message = errorList.get('5');
            else
            	this.message = errorList.get('1');
            window.scrollTo(0, 0);
          } else {
            if (this.login.url.indexOf('/brokerLock') > -1) {
              this.router.navigate(['/brokerLock']);
            } else if (this.login.url.indexOf('/locked') > -1) {
            	window.location.href = this.login.url;
              //this.router.navigate(['/locked',{errorCode:this.login.status_code}]);
            } else if (this.login.url.indexOf('/update') > -1) {
              this.router.navigate(['/update']);
            } else {
              console.log('url ' + this.login.url);
              window.location.href = this.login.url;
            }
          }
        }
      });
    } else {
      // disable login button if no userId and password entered
      return;
    }
  }

  isEmpty() {
    if (this.user && this.user.userID && this.user.password) {
      return false;
    } else {
      return true;
    }
  }

  loadForgotPassword() {
    if (window.location.origin.toLowerCase().indexOf('/login') !== -1) {
      window.location.href = window.location.origin.replace('/login', '/forgot');
    } else {
      window.location.href = window.location.origin + '/forgot';
    }
  }

  generateSession() {
    let dt = new Date().getTime();
    this.uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g,
      function (c) {
        const r = (dt + Math.random() * 16) % 16 | 0; dt = Math.floor(dt / 16); return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
      });
    console.log('session: ' + this.uuid);
  }
  
  getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
}
}

