import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class TrusteerService {
      
      constructor(private httpclient: HttpClient) { }

      // calls login service
      getImageTrusteer(sessionId) {
            let _url = 'https://image.us.axa.com/86268/7fFTM?d=' + Math.random() +
						'&cid=4' + // For example, 4 
						'&si=2' + // For example, 2
						'&e=' + document.location.origin + // https://int-auth.equitable.com
						'&LSESSIONID=' + sessionId + //from the login/auth service
						'&t=jsonp' + // constant value.
						'&c=gnhbpoxslpup_ses' + // For example, gnhbpoxslpup_ses
						'&eu=' + document.location.href ; // https://test.us.axa.com/uls/jsp/uls_reg_step1.jsp?trust=true 
            return this.httpclient.get<any>(_url);
      }
      
}
