import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
  name: 'vuidlen'
})
export class ValidationUidNum implements PipeTransform {

  constructor() { }

  transform(value: string): string {
    // Min 7 characters
    if (value && value.length >= 7) {
      return 'valid';
    }
    // Min 7 characters
   return 'invalid';
  }
}
