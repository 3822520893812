import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
  name: 'passUp'
})
export class ValidationPassUp implements PipeTransform {

  constructor() { }

  transform(value: string): string {
    // Uppercase
    if (/[A-Z]+/.test(value)) {
      return 'valid';
    }
   return 'invalid';
  }
}
