import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'redirectfilter'
})
export class HostRedirectPipe implements PipeTransform {

  transform(value: string): string {
    const host = window.location.hostname;
    const dev = 'https://dev.equitable.com/';
    const prod = 'https://www.equitable.com/';
    if (host.indexOf('int') !== -1 || host.indexOf('dev') !== -1) {
      value = dev + value;
    } else {
      value = prod + value;
    }
    return value;
  }
}
