import { Component } from '@angular/core';
import { AppInsightsService } from './shared/AppInsights.Service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'EQH';
  
  getCookie(name) {
    var value = "; " + document.cookie;
  	var parts = value.split("; " + name + "=");
  	if (parts.length == 2) return parts.pop().split(";").shift(); else return '';
  }
  
  constructor(private appInsightsService: AppInsightsService) {
  	this.appInsightsService.setAuthenticatedUserContext(this.getCookie("globalSessionId"), "ULS");
  }
}
