import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable()
export class PreviousUrlService {
  url$: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);
  constructor(private httpclient: HttpClient) {}

  getPrevURL(): Observable<string> {
        return this.url$.asObservable();
  }

   setPrevURL(url: string) {
     this.url$.next(url);
   }

}
