export class Login {
    url: string;
    status_code:string;
    message:string;
    constructor(url: string, status_code: string, message: string) {
        this.url = url;
        this.status_code = status_code;
        this.message = message;
    }
}
