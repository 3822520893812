import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-broker-locked',
  templateUrl: './broker-locked.component.html',
  styleUrls: ['./broker-locked.component.scss']
})
export class BrokerLockedComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
