import { Injectable } from '@angular/core';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class AppInsightsService {

  private appInsights: ApplicationInsights;

  constructor() {
    this.appInsights = new ApplicationInsights({ config: {
      instrumentationKey: environment.applicationInsights.instrumentationKey,
      enableCorsCorrelation: true,
      enableAutoRouteTracking: true
    } });
    this.appInsights.loadAppInsights();
    const telemetryInitializer = (envelope) => {
      envelope.tags['ai.cloud.role'] = 'Login UI';
    };
    this.appInsights.addTelemetryInitializer(telemetryInitializer);
    this.appInsights.trackPageView();
  }

  logPageView(name?: string, url?: string): void { // option to call manually
    this.appInsights.trackPageView({
      name,
      uri: url
    });
  }

  logEvent(name: string, properties?: { [key: string]: any }): void {
    this.appInsights.trackEvent({ name}, properties);
  }

  logMetric(name: string, average: number, properties?: { [key: string]: any }): void {
    this.appInsights.trackMetric({ name, average }, properties);
  }

  logException(exception: Error, severityLevel?: number): void {
    this.appInsights.trackException({ exception, severityLevel });
  }

  logTrace(message: string, properties?: { [key: string]: any }): void {
    this.appInsights.trackTrace({ message}, properties);
  }

  setAuthenticatedUserContext(username: string, role: string): void {
    this.appInsights.setAuthenticatedUserContext(username, role);
  }

  clearAuthenticatedUserContext(): void {
    this.appInsights.clearAuthenticatedUserContext();
  }
}
