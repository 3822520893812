export class User {
    // displayed information
    id: number;
    firstName: string;
    lastName: string;

    // request for login (/uls/login)
    userID: string;
    dfaToken: string;
    password: string;
    phonenumber: string;
    remember: boolean;
    session: string;
    status_code: string;
    
    //request for aggregator (/login/aggregator)
    aggregator: string;

    // alert
    alert: string;

    // init
    url: string;
}
