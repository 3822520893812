import { Component, OnInit } from '@angular/core';
import { HttpHeaders,HttpClient} from '@angular/common/http';
import { Router } from '@angular/router';

@Component({
  selector: 'app-global-footer',
  templateUrl: './global-footer.component.html',
  styleUrls: ['./global-footer.component.scss']
})
export class GlobalFooterComponent implements OnInit {
  htmlString :any = '';
  url : string = '/html/footer.html';
  public isCes: boolean = false;
  public isEqh: boolean = false;
  constructor(
    public http: HttpClient,
    private router: Router
  ){
    
  }

  ngOnInit() {
    let HTTPOptions:Object = {
      headers: new HttpHeaders({
          'Content-Type': 'application/json'
      }),
      responseType: 'text'
   }

  if(this.router.url.indexOf('/ces') !== -1){
    this.url = '/html/ces-footer.html';
    this.isCes = true;
  }else{
    this.isEqh = true;
  }
    const request = this.http.get<string>(this.url, HTTPOptions)
    .subscribe(res => {
      this.htmlString = res;
    });
    
    document.onload = function () {
      document.getElementsByTagName("body")[0].style.marginBottom = document.getElementsByTagName("footer")[0].offsetHeight + 75 + "px";
    };
    
    var intervalVar = setInterval(checkFooter, 1000);
	function checkFooter() {
		console.log('Inside timer for footer');
		var marginBottom = parseInt(document.getElementsByTagName("body")[0].style.marginBottom);
		var offsetHeight = document.getElementsByTagName("footer")[0].offsetHeight;
		if (!isNaN(marginBottom) && (marginBottom > offsetHeight)) {
			clearInterval(intervalVar);
			console.log('timer for footer is clear');
		} else{
			document.getElementsByTagName("body")[0].style.marginBottom = offsetHeight + 75 + "px";
		}	
	}
  }

}
