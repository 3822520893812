// Error contains error code and error messages
export const errorList = new Map([
    [ '1',
    'We don\'t recognize your User ID or Password. ' +
    'Please verify your information and try again. ' +
    'Your account will be locked after five failed login attempts.' ],
    [ '2', 'Your new password cannot be the same as your current password. Please re-enter your information below.' ],
    [ '3', 'The confirmation password that you typed is incorrect. Please re-enter your information below.' ],
    [ '4', 'Password must contain a number, a letter and at least 8 characters' ],
    [ '5', 'It looks like you\'re trying to sign in to Equitable to file an Employee/Group Benefits claim. ' +
    'You may have entered an incorrect password, please try again. ' +
    'If you do not have an Equitable account please click <a href="https://qa.ebadmin.equitable.com/eb-claims/register" class="btn-link">here</a> to register as an Employee/Group Benefits member. ' +
    'If you have any question please give our customer service team a call at 1-866-274-9887 for help. ' +
    'We\'re here to assist you' ]
]);
