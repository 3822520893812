import { ErrorHandler, Injectable} from '@angular/core';
import { AppInsightsService } from '../shared/AppInsights.Service';

@Injectable()
export class ErrorHandlerService extends ErrorHandler {

    constructor(private appInsights: AppInsightsService) {
        super();
    }

    handleError(error: Error) {
        this.appInsights.logException(error); // Manually log exception
    }
}