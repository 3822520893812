import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { errorList } from '../shared/error';
import { Router } from '@angular/router';
import { LoginService } from '../shared/Login.Service';
import { Update } from '../shared/update.model';
import { Login } from '../shared/login.model';
import { User } from '../shared/user.model';
import { ForcedPwd } from '../shared/ForcedPwd.model';
import { AppInsightsService } from '../shared/AppInsights.Service';

@Component({
  selector: 'app-expired-pw',
  templateUrl: './expired-pw.component.html',
  styleUrls: ['./expired-pw.component.scss']
})
export class ExpiredPwComponent implements OnInit {
  public one = true; // page 1
  public showPlaceholder: boolean;
  public oldpwd = '';
  public newpwd = '';
  public cpwd = '';
  public uid = '';
  public message = '';
  public error: boolean;
  public res: Login;
  public login: Login;
  public forcedPwd: ForcedPwd;
  public type: string;
  public uuid: string;
  public validate = true;
  public isLoading = false;
  public formFieldErrors: any = {
    cpwd: false,
    pwd: false,
    pwdc: false,
    invalidPass: false,
    newPass: false,
    PwdMaxlenthExceed: false
  };
  constructor(private router: Router,private loginService: LoginService, private appInsightsService: AppInsightsService) { }

  @ViewChild('oldpass') public oldpass: ElementRef;
  @ViewChild('newpass') public newpass: ElementRef;
  @ViewChild('repass') public repass: ElementRef;
  @ViewChild('passInfo') public passInfo: ElementRef;

  ngOnInit() {
    window.scroll(0, 0);
    this.showPlaceholder = true;    
    this.appInsightsService.logEvent("Login UI Update Password Page");
    // This is for session expired for update password direct hit
    this.loginService.verifySession().subscribe(res => {
      this.res = res;
      if (this.res) {
          if (this.res.status_code === '200') {
            console.log("success");
          } else if (this.res.status_code === '-1') {
            window.location.href = window.location.origin + '/login';
          }
      }
    });
    this.loginService.getUid().subscribe(res => {
      this.uid = res;
    });
  }
  
  validatePassword(str) {
	const liElements = this.passInfo.nativeElement.children;
	let valid = true;
	for (let a=0;a< liElements.length;a++) {
		if (liElements[a].className.indexOf('invalid') !== -1) {
			valid = false;
			break;
		}
	}
	return valid;
  }
  
  focusOnInput() {
    let setError = false;
    if (!!this.formFieldErrors.opwd) {
      setError = true;
      setTimeout(() => this.oldpass.nativeElement.focus(), 0);
      setTimeout(() => this.oldpass.nativeElement.select(), 0);
      window.scrollTo(0,this.oldpass.nativeElement.getBoundingClientRect().top + window.scrollY - 150);
    } else if (!!this.formFieldErrors.pwd || !!this.formFieldErrors.invalidPass || !!this.formFieldErrors.newPass) {
      setError = true;
      setTimeout(() => this.newpass.nativeElement.focus(), 0);
      setTimeout(() => this.newpass.nativeElement.select(), 0);
      window.scrollTo(0,this.newpass.nativeElement.getBoundingClientRect().top + window.scrollY - 150);
    } else if (!!this.formFieldErrors.pwdc || !!this.formFieldErrors.mismatch) {
      setError = true;
      setTimeout(() => this.repass.nativeElement.focus(), 0);
      setTimeout(() => this.repass.nativeElement.select(), 0);
      window.scrollTo(0,this.repass.nativeElement.getBoundingClientRect().top + window.scrollY - 150);
    }

    return setError;
  }

  isErrorPresent() {
    if (!this.formFieldErrors.opwd && !this.formFieldErrors.pwd && !this.formFieldErrors.invalidPass && 
      !this.formFieldErrors.newPass && !this.formFieldErrors.pwdc && !this.formFieldErrors.mismatch) {
      return false;
    } else {
      return true;
    }
  }

  validateAllFields() {
   
    const fields = ['oldpwd', 'newpwd', 'cpwd'];
    let isErrPresent = false;
    for (let j = 0; j < fields.length; j++) {
      isErrPresent = this.validateForm(fields[j]);
      if (isErrPresent) {
        break;
      }
    }
    return isErrPresent;
  }

  ifAtleastOneFieldValidated(model) {
    this.validate = !!this[model] ? false : true;
    return this.validate;
  }
  
  checkSeq(str){
    const seq = /123|345|456|678|890|111|222|333|444|555|666|777|888|999|000/;
    return seq.test(str);
  }
  
  setPlaceholder(ele) {
    if (this.showPlaceholder) {
      ele.setAttribute('placeholder', ele.getAttribute('data-placeholder'));
    } else {
      ele.setAttribute('placeholder', '');
    }
  }
  
  togglePlaceholder(event: FocusEvent) {
    this.showPlaceholder = !this.showPlaceholder;
    this.setPlaceholder(event.target);
  }
  
  toggleForm(event: FocusEvent, model) {
    this.showPlaceholder = !this.showPlaceholder;
    this.setPlaceholder(event.target);
    this.validateForm(model);
  }
  
  validateOnkeyUp(model){
	  if(model === 'newpwd' || model === 'cpwd'){
	  	  if (this.newpwd.length > 24) {
				this.formFieldErrors.PwdMaxlenthExceed = true;
				this.formFieldErrors.invalidPass = false;
				this.newpwd = this.newpwd.slice(0,25);
		  } else {
				this.formFieldErrors.PwdMaxlenthExceed = false;
		  }	
	      if(!!this.newpwd && !!this.cpwd && this.newpwd === this.cpwd){
	        this.validateForm('cpwd');
	      }
	    }
  }
  
  validateForm(model) {
    let error = false;
    this.validate = true;
    if (model === 'oldpwd') {
      if (!this.oldpwd) {
        this.formFieldErrors.opwd = true;
        error = true;
      } else {
        this.formFieldErrors.opwd = false;
        this.ifAtleastOneFieldValidated(model);
      }
    }

    if (model === 'newpwd') {
      if (!this.newpwd) {
        this.formFieldErrors.pwd = true;
        this.formFieldErrors.invalidPass = false;
        this.formFieldErrors.newPass = false;
        error = true;
      } else if (!this.validatePassword(this.newpwd)) {
        this.formFieldErrors.pwd = false;
        this.formFieldErrors.invalidPass = true;
        this.formFieldErrors.newPass = false;
        error = true;
      } else if (this.newpwd === this.oldpwd) {
        this.formFieldErrors.pwd = false;
        this.formFieldErrors.invalidPass = false;
        this.formFieldErrors.newPass = true;
        error = true;
      } else {
        this.formFieldErrors.pwd = false;
        this.formFieldErrors.newPass = false;
        this.formFieldErrors.invalidPass = false;
        this.ifAtleastOneFieldValidated(model);
      }
      
      if(this.newpwd.length>24){
		this.formFieldErrors.pwd = false;
		this.formFieldErrors.invalidPass = false;
		this.formFieldErrors.PwdMaxlenthExceed = true;
		error = true;
	  }
    }

    if (model === 'cpwd') {
      if (!this.cpwd) {
        this.formFieldErrors.pwdc = true;
        this.formFieldErrors.mismatch = false;
        error = true;
      } else if (this.cpwd !== this.newpwd) {
        this.formFieldErrors.pwdc = false;
        this.formFieldErrors.mismatch = true;
        error = true;
      } else {
        this.formFieldErrors.pwdc = false;
        this.formFieldErrors.mismatch = false;
        this.ifAtleastOneFieldValidated(model);
      }
    }

    console.log(this.formFieldErrors);
    if (!!this.oldpwd && !!this.newpwd && !!this.cpwd && !this.isErrorPresent() && !error) {
      this.validate = error;
    }
    return error;
  }

  isEmpty() {
    if (this.oldpwd && this.newpwd && this.cpwd) {
      return false;
    }
    return true;
  }

  onSubmit() {
    this.error = false;
    if(this.checkSeq(this.newpwd) || !this.validatePassword(this.newpwd)){
      this.error = true;
      this.message = 'Your new password must be 8 to 24 characters in length, must include an upper case letter, a lower case letter, a number and a special character (such as !,*,#,etc.), and may not include some common words or character strings (such as 12345).';
      window.scroll(0, 0);
      return;
    }
    if (!this.validateAllFields()) {
      const update = new Update();
      update.newPwd = this.newpwd;
      update.oldPwd = this.oldpwd;
      update.userID = this.uid;
      setTimeout(() => {
        this.error = false;
        this.isLoading = true;
        this.message = '';
        this.validate = true;
      });
      this.loginService.updatePwd(update).subscribe(forcedPwd => {
        setTimeout(() => {
          this.isLoading = false;
        });
        this.forcedPwd = forcedPwd;
        if (this.forcedPwd) {
          window.scrollTo(0, 0);
          if (this.forcedPwd.status_code === '200') {
            this.one = false; // page 2
          } else if (this.forcedPwd.status_code === '1015' || this.forcedPwd.status_code === '1020'
          	|| this.forcedPwd.status_code === '1021' || this.forcedPwd.status_code === '1022'
          	|| this.forcedPwd.status_code === '1023' || this.forcedPwd.status_code === '1024'
          	|| this.forcedPwd.status_code === '1025') {
            window.scroll(0, 0);
            this.error = true;
            this.validate = false;
            this.message = this.forcedPwd.message;
          } else {
            window.scroll(0, 0);
            this.error = true;
            this.validate = false;
            this.message = 'Something has gone wrong, please try again.';
          }
        }
      });
    } else {
      this.focusOnInput();
    }
  }

  submit() {
    const user = new User();
    user.userID = this.uid;
    user.password = this.newpwd;
    user.session = this.uuid;
    this.loginService.getLogin(user).subscribe(res => {
      this.login = res;
      if (this.login) {
        if (this.login.url.length === 0) {
          window.scroll(0, 0);
          this.error = true;
          this.message = errorList.get('1');
        } else {
          window.location.href = this.login.url;
        }
      }
    });
  }

  logout() {
    // clear cookie and redirect to public site
    this.loginService.getLogout().subscribe(res => {
      this.login = res;
      if (this.login) {
        if (this.login.url.length === 0) {
          window.scroll(0, 0);
          this.error = true;
          this.message = errorList.get('1');
        } else {
          console.log('url ' + this.login.url);
          window.location.href = this.login.url;
        }
      }
    });
  }

  isValid() {
    const letter = /[a-zA-Z]/;
    const num = /[0-9]/;
    const valid = num.test(this.newpwd) && letter.test(this.newpwd) && this.newpwd.length >= 8;
    return valid;
  }

  generateSession() {
    let dt = new Date().getTime();
    this.uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g,
      function (c) {
        const r = (dt + Math.random() * 16) % 16 | 0; dt = Math.floor(dt / 16); return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
      });
    console.log('session: ' + this.uuid);
  }
}
