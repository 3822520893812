import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'emailfilter'
})
export class EmailMaskPipe implements PipeTransform {

  transform(email: string): string {
      if (email && !email.includes('@')) {
        return email;
      }
      let arr = email.split('@');
      // process text before @
      let letter1 = arr[0][0] + '*'.repeat(arr[0].length - 2) + arr[0].slice(-1);
      return letter1 + '@' + arr[1];
  }
}
