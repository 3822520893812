import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Login } from './login.model';
import 'rxjs/add/operator/map';
import { User } from './user.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { Update } from './update.model';
import { ForcedPwd } from '../shared/ForcedPwd.model';

@Injectable()
export class LoginService {
      public data: Login;
      public resp: any;
      // prefix is login
      public auth = '/login/auth';
      public init = '/login/init';
      type: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);
      uid: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);
      err: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(undefined);
      msg: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);
      constructor(private httpclient: HttpClient) { }

      // calls login service
      getLogin(user: User) {
            const bodyString = JSON.stringify(user);
            const headers = new HttpHeaders({ 'Content-Type': 'application/JSON' });
            return this.httpclient.post<Login>(this.auth, bodyString, { headers }).map(data => data);
      }

      getLogout() {
            return this.httpclient.post<Login>('/login/off', {}).map(data => data);
      }
      
      verifySession() {
            const headers = new HttpHeaders({ 'Content-Type': 'application/JSON' });
            return this.httpclient.post<ForcedPwd>('/login/verifysession', '', {headers}).map(data => data);
      }

      updatePwd(update: Update) {
            const bodyString = JSON.stringify(update);
            console.log('body: ' + bodyString);
            const headers = new HttpHeaders({ 'Content-Type': 'application/JSON' });
            return this.httpclient.post<ForcedPwd>('/login/updatePwd', bodyString, {headers}).map(data => data);
      }

      getUser() {
            const url = window.location.href;
            const user = new User();
            user.url = url;
            const bodyString = JSON.stringify(user);
            console.log('body: ' + bodyString);
            const headers = new HttpHeaders({ 'Content-Type': 'application/JSON' });
            return this.httpclient.post<User>(this.init, bodyString, {headers}).map(data => data);
      }

      getType(): Observable<string> {
        return this.type.asObservable();
      }

      setType(types: string) {
        this.type.next(types);
      }

      setUid(uid: string) {
        this.uid.next(uid);
      }

      getUid(): Observable<string> {
        return this.uid.asObservable();
      }

      setError(error: boolean) {
        this.err.next(error);
      }

      getError(): Observable<boolean> {
        return this.err.asObservable();
      }
      setMsg(msg: string) {
        this.msg.next(msg);
      }

      getMsg(): Observable<string> {
        return this.msg.asObservable();
      }

      getNativeWindow() {
            return window;
      }
}
